import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_workspace_users_filter = _resolveComponent("workspace-users-filter")!
  const _component_workspace_users_table = _resolveComponent("workspace-users-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_workspace_users_filter, {
      "sub-accounts": _ctx.subAccounts,
      selected: _ctx.selected
    }, null, 8, ["sub-accounts", "selected"]),
    _createVNode(_component_workspace_users_table, {
      modelValue: _ctx.selected,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event)),
      headers: _ctx.usersHeaders,
      items: _ctx.users,
      "items-name": _ctx.subAccounts ? 'sub-accounts' : 'users',
      "sub-accounts": _ctx.subAccounts,
      "show-selection": ""
    }, null, 8, ["modelValue", "headers", "items", "items-name", "sub-accounts"])
  ]))
}