export const users = [
  {
    person: {
      fullName: 'John Doe',
      avatarColor: 'orange',
    },
    email: 'johndoe@gmail.com',
    status: {
      name: 'active',
      color: 'green',
      outline: true,
    },
    role: {
      name: 'admin',
      color: 'blue',
      outline: true,
    },
  },
  {
    person: {
      fullName: 'Arlene McCoy',
      avatarColor: 'purple',
    },
    email: 'arlenemccoy@gmail.com',
    status: {
      name: 'invited',
      color: 'blue',
      outline: true,
    },
    role: {
      name: 'admin',
      color: 'blue',
      outline: true,
    },
  },
  {
    person: {
      fullName: 'Esther Howard',
      avatarColor: 'green',
    },
    email: 'estherhoward@gmail.com',
    status: {
      name: 'invited',
      color: 'blue',
      outline: true,
    },
    role: {
      name: 'admin',
      color: 'blue',
      outline: true,
    },
  },
  {
    person: {
      fullName: 'Dianne Russell',
      avatarColor: 'red',
    },
    email: 'nvt.isst.nute@gmail.com',
    status: {
      name: 'invited',
      color: 'blue',
      outline: true,
    },
    role: {
      name: 'user',
      color: 'gray',
      outline: true,
    },
  },
]
