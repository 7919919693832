
import { defineComponent } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import TmPerson from '@/components/shared/TmPerson.vue'
import TmButton from '@/components/shared/TmButton.vue'
import WorkspaceUsersDropdown from '@/components/pages/admin/workspace/users/WorkspaceUsersDropdown.vue'

export default defineComponent({
  components: { TmButton, TmPerson, TmTable, TmStatus, WorkspaceUsersDropdown },
})
