
import { defineComponent, ref } from 'vue'
import TopFilter from '@/components/layout/topFilter/TopFilter.vue'
import TopFilterButton from '@/components/layout/topFilter/TopFilterButton.vue'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  components: {
    TopFilter,
    TopFilterButton,
  },
  props: {
    selected: {
      type: Array,
    },
    subAccounts: {
      type: Boolean,
    },
  },
  setup() {
    const search = ref('')
    const { openModal } = useModals()

    return {
      openModal,
      search,
    }
  },
})
