
import { computed, defineComponent, ref } from 'vue'
import WorkspaceUsersTable from '@/components/pages/admin/workspace/users/WorkspaceUsersTable.vue'
import WorkspaceUsersFilter from '@/components/pages/admin/workspace/users/WorkspaceUsersFilter.vue'
import { users } from '@/definitions/admin/users/data'

export default defineComponent({
  components: {
    WorkspaceUsersFilter,
    WorkspaceUsersTable,
  },
  props: {
    subAccounts: {
      type: Boolean,
    },
  },
  setup(props) {
    const selected = ref<any[]>([])
    const baseHeaders = [
      { text: 'Name', value: 'name' },
      { text: 'Email', value: 'email' },
      { text: 'Role', value: 'role' },
      { text: 'Status', value: 'status' },
    ]
    const usersHeaders = computed(() => {
      if (props.subAccounts) {
        return [
          ...baseHeaders,
          { text: 'Log in', value: 'login', hideSortable: true },
        ]
      }

      return baseHeaders
    })

    return {
      users,
      usersHeaders,
      selected,
    }
  },
})
